import React from 'react'

const ResponsiveIframe = ({url, title="Wistia video player"}) => {

return (
    <div className="box alt">
        <div className="row gtr-50 gtr-uniform">
            <div className="col-12">
                <div className="iframe-container ">
                    <iframe allowtransparency="true" 
                    title={title}
                    allowFullscreen frameborder="0" scrolling="no"
                    className="wistia_embed" 
                    name="wistia_embed"
                    src={url} >
                    </iframe>
                </div>
            </div>
        </div>
    </div>
);

}

export default React.memo(ResponsiveIframe);